import { Form, Formik } from 'formik'
import { Collapse, Divider, makeStyles, Switch, Tooltip } from '@material-ui/core'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import { useMutation, useQuery } from 'react-query'
import QueryKeys from '../../../utils/constants/queryKeys'
import getMetaConfiguration from '../../../Apis/social/getMetaConfiguration'
import metaConfigurationStyles from './metaConfiguration.styles'
import { useTranslate } from 'react-admin'
import React from 'react'
import UseQueryParams from '../../../hooks/useQueryParams'
import LoaderDots from '../../../components/loaders/LoaderDots/LoaderDots'
import updateMetaConfiguration from '../../../Apis/social/updateMetaConfiguration'
import isOrgAddonsValid from '../../../utils/helpers/isOrgAddonsValid'
import { USER_SCOPES } from '../../../utils/constants/userScopes'
import MyTextField from "../../../components/form/fields/MyTextField";

const MetaConfiguration = (props) => {
  const { invalidateQueries } = props
  const queryParams = UseQueryParams()
  const { org: orgId } = queryParams
  const translate = useTranslate()
  const useStyles = makeStyles(metaConfigurationStyles)
  const classes = useStyles()

  const patchConfigurationMutation = useMutation((data) => updateMetaConfiguration(data), {
    onSuccess: ({ data }, { resetForm }) => {
      resetForm({
        values: {
          postGameMvp: data.postGameMvp,
          postGameAnnouncement: data.postGameAnnouncement,
          postQuarterResult: data.postQuarterResult,
          postGameResult: data.postGameResult,
          gameAnnouncementPostNHoursBefore: data.gameAnnouncementPostNHoursBefore?.split(':')[0] || null,
          gameAnnouncementPostNMinsBefore:data.gameAnnouncementPostNHoursBefore?.split(':')[1] || null,
        },
      })
      invalidateQueries()
    },
  })

  const { data: configuration, isLoading } = useQuery([QueryKeys.GET_META_CONFIGURATION], () =>
    getMetaConfiguration(orgId),
  )

  const isAnnouncementTimeFieldRequired = (values) => {
    if (!values.postGameAnnouncement) return false

    return !(values.gameAnnouncementPostNHoursBefore || values.gameAnnouncementPostNMinsBefore)
  }

  const calculateGameAnnouncementTime = (values) => {
    if (!values.gameAnnouncementPostNHoursBefore) {
      return `00:${values.gameAnnouncementPostNMinsBefore.toString().padStart(2, '0')}`
    } else if (!values.gameAnnouncementPostNMinsBefore) {
      return `${values.gameAnnouncementPostNHoursBefore.toString().padStart(2, '0')}:00`
    } else
      return `${values.gameAnnouncementPostNHoursBefore.toString().padStart(2, '0')}:${values.gameAnnouncementPostNMinsBefore.toString().padStart(2, '0')}`
  }

  const onChangeSwitchState = (e, values, setValue) => {
    const valueName = e.target.name
    setValue(valueName, !values[valueName])
  }
  const onSubmitClick = (values, resetForm, patchConfiguration) => {
    const formattedValues = {
      ...values,
      gameAnnouncementPostNHoursBefore: calculateGameAnnouncementTime(values),
    }
    patchConfiguration.mutate({ orgId, configuration: formattedValues, resetForm })
  }

  if (isLoading) return <LoaderDots style={{ marginLeft: 10 }} />

  return (
    <Formik
      initialValues={{
        postGameMvp: configuration?.postGameMvp ?? false,
        postGameAnnouncement: configuration?.postGameAnnouncement ?? false,
        postQuarterResult: configuration?.postQuarterResult ?? false,
        postGameResult: configuration?.postGameResult ?? false,
        gameAnnouncementPostNHoursBefore: configuration?.gameAnnouncementPostNHoursBefore?.split(':')[0] || null,
        gameAnnouncementPostNMinsBefore: configuration?.gameAnnouncementPostNHoursBefore?.split(':')[1] || null
      }}
      onSubmit={(values, { resetForm }) => {
        onSubmitClick(values, resetForm, patchConfigurationMutation)
      }}
    >
      {({ values, setFieldValue, dirty }) => (
        <Form className={classes.formContainer}>
          <Divider />
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldSpacing}>
              {translate('ra.text.socialConfAnnouncement')}
              <Switch
                className={classes.toggle}
                name='postGameAnnouncement'
                checked={values.postGameAnnouncement}
                onChange={(e) => onChangeSwitchState(e, values, setFieldValue)}
              />
            </div>
            <Collapse in={values.postGameAnnouncement}>
              <div style={{ width: 200, marginLeft: 'auto', display: 'flex', gap: 10 }}>
                <MyTextField
                    name='gameAnnouncementPostNHoursBefore'
                    label={'Hours'}
                    type='number'
                    disabled={!values.postGameAnnouncement}
                    required={isAnnouncementTimeFieldRequired(values)}
                    inputProps={{
                      inputProps: {
                        min: 0,
                        max: 23,
                      },
                    }}
                />
                <MyTextField
                    name='gameAnnouncementPostNMinsBefore'
                    label={'Mins'}
                    type='number'
                    disabled={!values.postGameAnnouncement}
                    required={isAnnouncementTimeFieldRequired(values)}
                    inputProps={{
                      inputProps: {
                        min: 0,
                        max: 59,
                      },
                    }}
                />
              </div>
            </Collapse>
          </div>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldSpacing}>
              {translate('ra.text.socialConfMvp')}
              <Tooltip title='Activate Stats addon to get access!'>
                <div>
                  <Switch
                    className={classes.toggle}
                    name='postGameMvp'
                    checked={values.postGameMvp}
                    onChange={(e) => onChangeSwitchState(e, values, setFieldValue)}
                    disabled={!isOrgAddonsValid([USER_SCOPES.STATS])}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldSpacing}>
              {translate('ra.text.socialConfQuarter')}
              <Tooltip title='Activate Stats addon to get access!'>
                <div>
                  <Switch
                    className={classes.toggle}
                    name='postQuarterResult'
                    checked={values.postQuarterResult}
                    onChange={(e) => onChangeSwitchState(e, values, setFieldValue)}
                    disabled={!isOrgAddonsValid([USER_SCOPES.STATS])}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={classes.fieldsContainer}>
            <div className={classes.fieldSpacing}>
              {translate('ra.text.socialConfResult')}
              <Tooltip title='Activate Stats addon to get access!'>
                <div>
                  <Switch
                    className={classes.toggle}
                    name='postGameResult'
                    checked={values.postGameResult}
                    onChange={(e) => onChangeSwitchState(e, values, setFieldValue)}
                    disabled={!isOrgAddonsValid([USER_SCOPES.STATS])}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <ButtonWithLoader
              label={translate('ra.buttons.save')}
              disabled={!dirty}
              loading={patchConfigurationMutation.isLoading}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default MetaConfiguration
