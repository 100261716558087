import axios from 'axios'
import generalHeader from '../../utils/constants/generalHeader'
import { BASE_URL } from '../../utils/constants/url'

const updateMetaConfiguration = (data) => {
  data
  const { orgId, configuration } = data
  const url = `${BASE_URL}/social/configuration?orgId=${orgId}`

  return axios.patch(url, configuration, {
    headers: generalHeader(localStorage.getItem('token')),
  })
}

export default updateMetaConfiguration
